<template>
    <div>
  
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="elementData === undefined"
      >
        <h4 class="alert-heading">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data')}}
        </h4>
        <div class="alert-body">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check')}}
          <b-link
            class="alert-link"
            :to="{ name: 'apps-'+$t(this.RESOURCES_ELEMENT_NAME + '.object.module_name') +'-list'}"
          >
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list')}}
          </b-link>
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other')}}
        </div>
      </b-alert>
  
  
      <template v-if="elementData">
        <element-edit-tab
          :new-element="newElement"
          :element-data="elementData"
          :reason-list="reasonList"
          :result-list="resultList"
          :medium-list="mediumList"
          :resources-element-name="RESOURCES_ELEMENT_NAME"
          class="mt-2 pt-75"
        />
      </template>
      
    </div>
  </template>
  
  <script>
  import {
     BAlert, BLink
  } from 'bootstrap-vue'
  import router from '@/router'
  import store from '@/store'
  import elementStoreModule from '../storeModule'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import ElementEditTab from './EditCard.vue'
  import moment from 'moment'
  
  export default {
    components: {
      BAlert,
      BLink,
      ElementEditTab: ElementEditTab,
    },
    data() {
      return {
        RESOURCES_ELEMENT_NAME: '',
        ELEMENT_APP_STORE_MODULE_NAME: '',
        newElement: true,
        invoice_id: undefined,
        elementData: {},
        reasonList: [],
        resultList: [],
        mediumList: []
      }
    },
    async mounted() {
      this.RESOURCES_ELEMENT_NAME = 'logbook-invoice'
      this.ELEMENT_APP_STORE_MODULE_NAME = 'apps-lb-ed-' + this.$t(this.RESOURCES_ELEMENT_NAME + ".module_name")
      // Register module
      console.log('Route Params:', router.currentRoute.params);

      this.invoice_id = router.currentRoute.params.invoice_id
      if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) {
        store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule);
      }   
      try {
        this.reasonList = await this.fetchKeyValue('logbook_reason');
        this.resultList = await this.fetchKeyValue('logbook_result');
        this.mediumList = await this.fetchKeyValue('logbook_medium');
      } catch (error) {
        console.error('Error fetching key values:', error);
      }
      
      if (router.currentRoute.params.id !== undefined) {
        await this.fetchElement()
      } else {
        this.elementData = {status: 1, invoice_id: this.invoice_id, registry_time: moment().format('DD/MM/YYYY hh:mm A')}
      }
      
    },
    unmounted() {
      // UnRegister on leave
      if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
    },
    methods: {
        async fetchKeyValue(type) {
        return store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_key_value', { type:type })
        .then(response => { 
            var list = response.data.items 
            list.unshift({ id: null, value: this.$t('list.defaultOption') })
            return list
        }).catch(error => {
            if (error.response.status === 404) {
            return []
            }
        })
        },
        fetchElement() {
            this.newElement = false
            store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_logbook', { id: router.currentRoute.params.id })
                .then(response => { 
                this.elementData = response.data 
                if (this.elementData.logo_id) {
                    this.fetchLogo(this.elementData.logo_id)
                }
                })
                .catch(error => {
                if (error.response.status === 404) {
                    this.elementData = undefined
                }
                })

        },
        save(elementData) {
            let method = this.ELEMENT_APP_STORE_MODULE_NAME + (this.newElement ? '/add_element' : '/save_element')
            let action = this.newElement ? 'creation' : 'update'
            var _self = this
            console.log("Datos que se enviarán al backend:", elementData);
            store
                .dispatch(method, {data: elementData})
                .then(response => {
                _self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                    title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.title'),
                    icon: 'SaveIcon',
                    variant: 'success',
                    text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.success'),
                    },
                })
                console.log(_self.ELEMENT_APP_STORE_MODULE_NAME+'-list')
                _self.$router.go(-1)
                })
                .catch((error) => {
                _self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                    title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.title'),
                    icon: 'SaveIcon',
                    variant: 'danger',
                    text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.failure'),
                    },
                })
            })
        },
        fetchInvoiceData(invoice_id) {
            store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetchInvoice', { id: invoice_id })
            .then(response => {
                this.invoiceData = response.data
            })
            .catch(error => {
                if (error.response.status === 404) {
                this.invoiceData = undefined
                }
            })
        },
        fetchDepartments() {
            return store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetchDepartments')
            .then(response => response.data.items)
        },
        fetchCities(parentId) {
            return store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetchCities', { parent: parentId })
            .then(response => response.data.items)
        }
    }   
}
</script>

<style>

</style>
