var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Registro de evento en la Bitácora")])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"registry_time"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.registry_time')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('b-form-input',{attrs:{"id":"registry_time","disabled":true},model:{value:(_vm.elementData.registry_time),callback:function ($$v) {_vm.$set(_vm.elementData, "registry_time", $$v)},expression:"elementData.registry_time"}})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"folder_type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.management_medium')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.management_medium'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.mediumList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.getPlaceholderValue(_vm.mediumList)},model:{value:(_vm.elementData.management_medium_id),callback:function ($$v) {_vm.$set(_vm.elementData, "management_medium_id", $$v)},expression:"elementData.management_medium_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"folder_type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.reason')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.reason'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.reasonList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.getPlaceholderValue(_vm.reasonList)},model:{value:(_vm.elementData.reason_id),callback:function ($$v) {_vm.$set(_vm.elementData, "reason_id", $$v)},expression:"elementData.reason_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"folder_type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.result')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.result'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.resultList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.getPlaceholderValue(_vm.resultList)},model:{value:(_vm.elementData.result_id),callback:function ($$v) {_vm.$set(_vm.elementData, "result_id", $$v)},expression:"elementData.result_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"folder_type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.observations')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.observations'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"observations","maxlength":"500"},model:{value:(_vm.elementData.observations),callback:function ($$v) {_vm.$set(_vm.elementData, "observations", $$v)},expression:"elementData.observations"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",on:{"click":function($event){_vm.router().go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }